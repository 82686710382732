import {
  BlogFragment,
  BlogCardFragment,
  InviteInput,
  BlogFragmentDoc,
  BlogCardFragmentDoc,
  useFollowBlogMutation,
  useUnfollowBlogMutation,
  useGetBlogFollowersLazyQuery,
  useInviteUsersToActivityMutation,
  useUndoFollowRequestMutation,
  Invitable,
  BlogCardSlimFragment,
} from "@/graphql/types";

interface FetchFollowersOptions {
  limit: number;
}

type FetchFollowersByIdOptions = FetchFollowersOptions & {
  id: string;
};

export const useBlogsFollowersStore = (blog?: BlogCardFragment | BlogCardSlimFragment | null) => {
  const [fetchFollowers, { loading, data, error, fetchMore: fetchNextFollowersPage }] =
    useGetBlogFollowersLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const [follow, { loading: followLoading }] = useFollowBlogMutation();
  const [unfollow, { loading: unfollowLoading }] = useUnfollowBlogMutation();
  const [inviteUsersToActivity] = useInviteUsersToActivityMutation();
  const [undoFollowRequest, { loading: undoFollowRequestLoading }] = useUndoFollowRequestMutation();

  const followBlog = () => {
    if (blog) {
      return follow({
        variables: {
          id: blog.id,
        },
      });
    }
  };

  const unfollowBlog = () => {
    if (!!blog && blog.follows.isFollowedByMe) {
      return unfollow({
        variables: {
          id: blog.id,
        },
      });
    }
  };

  return {
    loading,
    error: error !== undefined,
    hasMorePages: data?.blog?.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.blog?.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.blog?.follows.followers.count || 0,
    loadingToggleFollow: followLoading || unfollowLoading || undoFollowRequestLoading,
    fetchFollowers({ limit }: FetchFollowersOptions) {
      if (blog) {
        return fetchFollowers({
          variables: {
            id: blog.id,
            limit,
          },
        });
      }
    },
    fetchFollowersById({ id, limit }: FetchFollowersByIdOptions) {
      if (id) {
        return fetchFollowers({
          variables: {
            id: id,
            limit,
          },
        });
      }
    },
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.blog?.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    followIfNotFollowing() {
      if (!!blog && !blog.follows.isFollowedByMe) {
        return followBlog();
      }
    },
    toggleFollow() {
      if (blog !== undefined && blog !== null) {
        if (blog.follows.isFollowedByMe) {
          return unfollowBlog();
        }
        return followBlog();
      }
    },
    unfollowById(id: string) {
      if (id) {
        return unfollow({
          variables: {
            id,
          },
        });
      }
    },
    invitePeople(ids: string[], inviteAll = false) {
      if (blog) {
        const data: InviteInput = {
          invitableId: blog.id,
          invitableType: Invitable.Blog,
          invitedUserIds: ids,
          inviteAll,
        };
        return inviteUsersToActivity({
          variables: {
            data,
          },
        });
      }
    },
    undoFollowRequest() {
      if (blog?.follows.followId) {
        return undoFollowRequest({
          variables: {
            id: blog.follows.followId,
          },
          update(cache, { data }) {
            if (data?.undoFollowRequest?.successful) {
              const result = cache.readFragment({
                id: `${blog.__typename}:${blog.id}`,
                fragment: BlogFragmentDoc,
                fragmentName: "Blog",
              }) as BlogFragment;

              if (result) {
                cache.writeFragment({
                  id: `${blog.__typename}:${blog.id}`,
                  fragment: BlogFragmentDoc,
                  fragmentName: "Blog",
                  data: {
                    ...result,
                    follows: {
                      ...result.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }

              const card = cache.readFragment({
                id: `${blog.__typename}:${blog.id}`,
                fragment: BlogCardFragmentDoc,
                fragmentName: "BlogCard",
              }) as BlogCardFragment;

              if (card) {
                cache.writeFragment({
                  id: `${blog.__typename}:${blog.id}`,
                  fragment: BlogCardFragmentDoc,
                  fragmentName: "BlogCard",
                  data: {
                    ...card,
                    follows: {
                      ...card.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }
            }
          },
        });
      }
    },
  };
};
