import {
  Invitable,
  InviteInput,
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  useFollowLiveStreamMutation,
  useGetLiveStreamFollowersLazyQuery,
  useInviteUsersToActivityMutation,
  useUnfollowLiveStreamMutation,
} from "@/graphql/types";

interface FetchFollowersOptions {
  limit: number;
}
type FetchFollowersByIdOptions = FetchFollowersOptions & {
  id: string;
};
export const useLiveStreamFollowersStore = (
  liveStream?: LiveStreamCardFragment | LiveStreamCardSlimFragment | null,
) => {
  const [fetchFollowers, { loading, data, error, fetchMore: fetchNextFollowersPage }] =
    useGetLiveStreamFollowersLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const [follow, { loading: followLoading }] = useFollowLiveStreamMutation();
  const [unfollow, { loading: unfollowLoading }] = useUnfollowLiveStreamMutation();
  const [inviteUsersToActivity] = useInviteUsersToActivityMutation();

  const followLiveStream = () => {
    if (liveStream) {
      return follow({
        variables: {
          id: liveStream.id,
        },
      });
    }
  };

  const unfollowLiveStream = () => {
    if (!!liveStream && liveStream.follows.isFollowedByMe) {
      return unfollow({
        variables: {
          id: liveStream.id,
        },
      });
    }
  };

  return {
    loading,
    error: error !== undefined,
    loadingToggleFollow: followLoading || unfollowLoading,
    hasMorePages: data?.liveStream?.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.liveStream?.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.liveStream?.follows.followers.count || 0,
    invitePeople(ids: string[], inviteAll = false) {
      if (liveStream) {
        const data: InviteInput = {
          invitableId: liveStream.id,
          invitableType: Invitable.LiveStream,
          invitedUserIds: ids,
          inviteAll,
        };
        return inviteUsersToActivity({
          variables: {
            data,
          },
        });
      }
    },
    followIfNotFollowing() {
      if (!!liveStream && !liveStream.follows.isFollowedByMe) {
        return followLiveStream();
      }
    },
    toggleFollow() {
      if (liveStream !== undefined && liveStream !== null) {
        if (liveStream.follows.isFollowedByMe) {
          return unfollowLiveStream();
        }
        return followLiveStream();
      }
    },
    unfollowById(id: string) {
      if (id) {
        return unfollow({
          variables: {
            id,
          },
        });
      }
    },
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.liveStream?.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    fetchFollowers({ limit }: FetchFollowersOptions) {
      if (liveStream) {
        return fetchFollowers({
          variables: {
            id: liveStream.id,
            limit,
          },
        });
      }
    },
    fetchFollowersById({ id, limit = 20 }: FetchFollowersByIdOptions) {
      if (id) {
        return fetchFollowers({
          variables: {
            id: id,
            limit,
          },
        });
      }
    },
  };
};
