import {
  EventFragment,
  EventCardFragment,
  InviteInput,
  EventFragmentDoc,
  EventCardFragmentDoc,
  useFollowEventMutation,
  useUnfollowEventMutation,
  useGetEventFollowersLazyQuery,
  useInviteUsersToActivityMutation,
  useUndoFollowRequestMutation,
  Invitable,
  EventCardSlimFragment,
} from "@/graphql/types";

interface FetchFollowersOptions {
  limit: number;
}

type FetchFollowersByIdOptions = FetchFollowersOptions & {
  id: string;
};

export const useEventsFollowersStore = (
  event?: EventCardFragment | EventCardSlimFragment | null,
) => {
  const [fetchFollowers, { loading, data, error, fetchMore: fetchNextFollowersPage }] =
    useGetEventFollowersLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const [follow, { loading: followLoading }] = useFollowEventMutation();
  const [unfollow, { loading: unfollowLoading }] = useUnfollowEventMutation();
  const [inviteUsersToActivity] = useInviteUsersToActivityMutation();
  const [undoFollowRequest, { loading: undoFollowRequestLoading }] = useUndoFollowRequestMutation();

  const followEvent = () => {
    if (event) {
      return follow({
        variables: {
          id: event.id,
        },
      });
    }
  };

  const unfollowEvent = () => {
    if (!!event && event.follows.isFollowedByMe) {
      return unfollow({
        variables: {
          id: event.id,
        },
      });
    }
  };

  return {
    loading,
    error: error !== undefined,
    hasMorePages: data?.event?.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.event?.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.event?.follows.followers.count || 0,
    loadingToggleFollow: followLoading || unfollowLoading || undoFollowRequestLoading,
    fetchFollowers({ limit }: FetchFollowersOptions) {
      if (event) {
        return fetchFollowers({
          variables: {
            id: event.id,
            limit,
          },
        });
      }
    },
    fetchFollowersById({ id, limit = 20 }: FetchFollowersByIdOptions) {
      if (id) {
        return fetchFollowers({
          variables: {
            id: id,
            limit,
          },
        });
      }
    },
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.event?.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    followIfNotFollowing() {
      if (!!event && !event.follows.isFollowedByMe) {
        return followEvent();
      }
    },
    toggleFollow() {
      if (event !== undefined && event !== null) {
        if (event.follows.isFollowedByMe) {
          return unfollowEvent();
        }
        return followEvent();
      }
    },
    unfollowById(id: string) {
      if (id) {
        return unfollow({
          variables: {
            id,
          },
        });
      }
    },
    invitePeople(ids: string[], inviteAll = false) {
      if (event) {
        const data: InviteInput = {
          invitableId: event.id,
          invitableType: Invitable.Event,
          invitedUserIds: ids,
          inviteAll,
        };
        return inviteUsersToActivity({
          variables: {
            data,
          },
        });
      }
    },
    undoFollowRequest() {
      if (event?.follows.followId) {
        return undoFollowRequest({
          variables: {
            id: event.follows.followId,
          },
          update(cache, { data }) {
            if (data?.undoFollowRequest?.successful) {
              const result = cache.readFragment({
                id: `${event.__typename}:${event.id}`,
                fragment: EventFragmentDoc,
                fragmentName: "Event",
              }) as EventFragment;

              if (result) {
                cache.writeFragment({
                  id: `${event.__typename}:${event.id}`,
                  fragment: EventFragmentDoc,
                  fragmentName: "Event",
                  data: {
                    ...result,
                    follows: {
                      ...result.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }

              const card = cache.readFragment({
                id: `${event.__typename}:${event.id}`,
                fragment: EventCardFragmentDoc,
                fragmentName: "EventCard",
              }) as EventCardFragment;

              if (card) {
                cache.writeFragment({
                  id: `${event.__typename}:${event.id}`,
                  fragment: EventCardFragmentDoc,
                  fragmentName: "EventCard",
                  data: {
                    ...card,
                    follows: {
                      ...card.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }
            }
          },
        });
      }
    },
  };
};
